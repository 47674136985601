import React from "react"
import { Link } from "gatsby"

export default function ProjectCard({ detail, location, link, children }) {
  return (
    <div className="flex justify-center items-start">
      <div className="max-w-xs xs:max-w-full sm:max-w-full md:max-w-full rounded-lg overflow-hidden shadow-xl mx-8 my-5 ease-in duration-200 hover:shadow">
        <Link to={link}>
          {children}
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{detail}</div>
            <p className="text-gray-700 text-base">{location}</p>
          </div>
        </Link>
      </div>
    </div>
  )
}
