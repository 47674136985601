import React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import Img from "gatsby-image"
import { Link } from "gatsby"

// icons
import { FcNext } from "react-icons/fc"
import { FcPrevious } from "react-icons/fc"

const projectList = ({ pageContext, data }) => {
  const { currentPage, projectPageTotal } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === projectPageTotal
  const prevPage =
    currentPage - 1 === 1 ? "/projects" : `/projects/${currentPage - 1}`
  const nextPage = isLast
    ? `/projects/${projectPageTotal}`
    : `/projects/${currentPage + 1}`

  const projects = data.allFile.edges

  return (
    <Layout>
      {projectPageTotal > 1 && (
        <div className="mb-4 fixed pt-16 pb-2 w-full bg-white z-10000 mt-2 shadow-lg">
          <div className="container flex justify-between mx-auto px-5">
            {!isFirst && (
              <Link
                to={prevPage}
                className="flex items-center hover:text-blue-500 duration-200"
              >
                <FcPrevious className="inline-block" />
                Previous Page
              </Link>
            )}
            <span></span>
            {!isLast && (
              <Link
                to={nextPage}
                className="flex items-center hover:text-blue-500 duration-200"
              >
                Next Page
                <FcNext className="inline-block" />
              </Link>
            )}
          </div>
        </div>
      )}
      <section className="container mx-auto pt-20 pb-10">
        <div className="flex flex-wrap md:flex-col sm:flex-col xs:flex-col mt-6">
          {projects.map(project => (
            <ProjectCard
              key={project.node.id}
              link={`/${project.node.id}`}
              detail={project.node.childMarkdownRemark.frontmatter.detail}
              location={project.node.childMarkdownRemark.frontmatter.location}
            >
              <Img
                fluid={
                  project.node.childMarkdownRemark.frontmatter.cardImg
                    .childImageSharp.fluid
                }
                alt={project.node.childMarkdownRemark.frontmatter.detail}
              />
            </ProjectCard>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query projectsQuery($skip: Int!, $limit: Int!) {
    allFile(
      filter: { sourceInstanceName: { eq: "markdown-project-pages" } }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            id
            frontmatter {
              title
              cardImg {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              detail
              location
            }
          }
        }
      }
    }
  }
`

export default projectList
